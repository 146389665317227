import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import farmProfile from './modules/farm_profile/farmProfile';
import farmLocations from './modules/farmLocations';
import budgeting from './modules/farm_profile/budgeting';
import user from './modules/user';
import chat from './modules/chat';
import content from './modules/content';
import permissions from './modules/permissions';
import preferences from './modules/preferences';
import subscription from './modules/subscription';
import snackbar from './modules/snackbar';
import shared from './modules/shared';
import weather from './modules/weather';
import cssModule from './modules/cssModule';
import gmaModule from './modules/gmaModule';
import marketingModule from './modules/marketingModule/marketingModule';
import managingContent from './modules/marketingModule/managingContent';
import launchDarkly from './modules/launchDarkly';
import videos from './modules/videos';
import chargebee from './modules/chargebee';
import priceMapper from './modules/priceMapper';
import riskProfile from './modules/riskProfile';
import mobileSlideout from './modules/priceMapper/mobileSlideout';
import cashflow from './modules/cashflow';
import region from './modules/region';
import buyer from './modules/buyer';
import buyerRegion from './modules/buyerRegion';
import gExpandableTable from './modules/gExpandableTable';
import group from './modules/group';
import dashboard from './modules/dashboard';
import smartAdvisor from './modules/smart-advisor/smart-advisor';
import news from './modules/news';
import nlg from './modules/nlg';
import mediaPlayer from './modules/mediaPlayer';
import contractScanner from './modules/contractScanner';
import discountCoupon from './modules/discountCoupon';
import recommendations from './modules/recommendations';
import scenarioPlanner from './modules/scenarioPlanner';
import couponManagement from './modules/marketingModule/couponManagement';
import analysisDashboard from './modules/analysisDashboard';
import buyerSmartAdvisor from './modules/buyerSmartAdvisor';

Vue.use(Vuex);

function sortModules(obj) {
  if (!obj) {
    return obj;
  }
  if (!('modules' in obj)) {
    return obj;
  }
  return {
    ...obj,
    modules: Object.entries(obj.modules)
      .sort()
      .map(([key, value]) => [key, sortModules(value)])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  };
}

export default new Vuex.Store(sortModules({
  modules: {
    app,
    farmProfile,
    farmLocations,
    budgeting,
    user,
    chat,
    content,
    permissions,
    preferences,
    subscription,
    snackbar,
    shared,
    weather,
    cssModule,
    marketingModule,
    managingContent,
    gmaModule,
    launchDarkly,
    videos,
    chargebee,
    priceMapper,
    riskProfile,
    mobileSlideout,
    cashflow,
    region,
    buyer,
    buyerRegion,
    gExpandableTable,
    group,
    dashboard,
    smartAdvisor,
    news,
    nlg,
    mediaPlayer,
    contractScanner,
    discountCoupon,
    recommendations,
    scenarioPlanner,
    couponManagement,
    analysisDashboard,
    buyerSmartAdvisor,
  },
}));
