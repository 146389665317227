import { MLAPIRequest } from '@/helpers/request';
import { MLAPI_ROOT } from '@/helpers/environment_variables';
import { createMLUrl } from '@/store/modules/smart-advisor/smart-advisor-context';

const MLAPI_COT_DATA = 'commitment_of_traders/fetch';
const MLAPI_INTEREST_RATE_DATA = 'interest_rates/fetch';
const MLAPI_FUTURES_PRICES = 'futures-prices';

function processPricesForCommodity(priceData) {
  const dates = Object.keys(priceData.close).sort((a, b) => new Date(a) - new Date(b));

  return {
    dates,
    open: dates.map((ts) => priceData.open[ts]),
    close: dates.map((ts) => priceData.close[ts]),
    high: dates.map((ts) => priceData.high[ts]),
    low: dates.map((ts) => priceData.low[ts]),
  };
}

export default {
  namespaced: true,
  state: () => ({
    selectedCommodity: 'corn',
    selectedCurrency: 'CAD',
    selectedDateRange: [],
    selectedIndicator: 'RSI',
    selectedChart: null,
    buyerSmartAdvisorCotData: {},
    buyerSmartAdvisorInterestRateData: {},
    buyerSmartAdvisorFuturesPrices: {},
    indicators: [
      'RSI',
      'Bollinger Bands',
      'MACD',
      'Simple Moving Average',
      'Commitment of Traders',
      'Interest Rates',
    ],
    chartData: {},
  }),
  mutations: {
    setSelectedCommodity(state, value) {
      state.selectedCommodity = value;
    },
    setSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    setSelectedDateRange(state, value) {
      state.selectedDateRange = value;
    },
    setSelectedIndicator(state, value) {
      state.selectedIndicator = value;
    },
    setSelectedChart(state, value) {
      state.selectedChart = value;
    },
    setBuyerSmartAdvisorCotData(state, value) {
      state.buyerSmartAdvisorCotData = value;
    },
    setBuyerSmartAdvisorInterestRateData(state, value) {
      state.buyerSmartAdvisorInterestRateData = value;
    },
    setChartData(state, value) {
      state.chartData = value;
    },
    setBuyerSmartAdvisorFuturesPrices(state, value) {
      state.buyerSmartAdvisorFuturesPrices = value;
    },
  },

  actions: {
    async getBuyerSmartAdvisorCotData({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_COT_DATA);

      try {
        const { commodities } = await MLAPIRequest(url.href, payload);

        commit('setBuyerSmartAdvisorCotData', commodities);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBuyerSmartAdvisorInterestRateData({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_INTEREST_RATE_DATA);

      try {
        const { interest_rates } = await MLAPIRequest(url.href, payload);

        commit('setBuyerSmartAdvisorInterestRateData', interest_rates);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBuyerSmartAdvisorFuturesPrices({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_FUTURES_PRICES);

      try {
        const { prices } = await MLAPIRequest(url.href, payload);

        Object.entries(prices).forEach(([commodity, priceData]) => {
          prices[commodity] = processPricesForCommodity(priceData);
        });
        commit('setBuyerSmartAdvisorFuturesPrices', prices);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },
};
