<template>
  <Editor
    :api-key="apiKey"
    cloud-channel="6"
    :init="config"
    :plugins="plugins"
    @onInit="handleInit"
    @onChange="handleInput"
    @onKeyDown="handleKeydown"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { registerSpeakButton, registerSpeakIcon } from '@/helpers/tts';
import { TINY_INIT, TINY_PLUGINS, LINK_INIT } from '@/config/editor';
import { TINYMCE_API_KEY } from '@/helpers/environment_variables';

export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      plugins: TINY_PLUGINS,
      apiKey: TINYMCE_API_KEY,
    };
  },

  computed: {
    config() {
      return {
        ...TINY_INIT,
        ...LINK_INIT,
        setup: (editor) => {
          registerSpeakIcon(editor);
          registerSpeakButton(editor);
        },
      };
    },
  },

  methods: {
    setContent(content) {
      if (content) {
        this.editor.setContent(content);
      }
    },
    handleInit(_, editor) {
      this.editor = editor;
      this.setContent(this.value);
      this.$emit('update-editor', this.editor);
    },
    handleKeydown(event, editor) {
      const element = editor.selection.getNode();

      // Prevent modifying protected elements
      if (element.classList.contains('mceNonEditable')) {
        // Allow certain keyboard keys
        if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Control', 'Shift', 'Tab'].includes(event.key)) {
          return true;
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      return true;
    },
    handleInput(_, editor) {
      const contents = editor.getContent();
      this.$emit('input', contents);
    },

    resetDocumentFocus() {
      this.$nextTick(() => {
        if (this.editor && this.editor.selection) {
          // Select the document <head> to remove selection
          this.editor.selection.select(this.editor.dom.doc.querySelector('head'));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/colors/colors.scss";

.tox {
  &.tox-tinymce {
    height: 100% !important;
    border: none;
    border-radius: 0;
  }
  & button.tox-button {
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    transition-duration: 0.3s;
    &[title="Save"], &[title="Insert"] {
      /* I am not sure if the comma notation works like this so I would double check this part */
      background-color: colors.$primary;
      border-color: colors.$primary;
      &:hover {
        background-color: colors.$primary;
        border-color: colors.$primary;
        opacity: 0.8;
      }
    }
  }
  & div.tox-dialog__body-nav-item--active {
    color: colors.$primary;
    border-bottom-color: colors.$primary;
    &:focus {
      background-color: #F477211a;
    }
  }
  &.tox-edit-area {
    background: #FAFAFA;
    &__iframe {
      width: calc(100% - var(--editor-slide-drawer-overlap)) !important;
      transition: width 200ms ease-in-out !important;
    }
  }
}

:root {
  --editor-slide-drawer-overlap: 0px;
}

.fedra-sans {
  font-family: 'fedra-sans';
}

.mce-toc ul li a, .mce-toc ul li ul li a{
  color: colors.$primary;
}

.mce-toc ul li ul li ul li a {
  color: colors.$primaryGrey;
}

</style>
